<template>
  <div class="container">
    <v-sheet min-height="35" />
    <v-row>
      <v-col cols="3" />
      <v-col cols="6">
        <v-card class="custom-form">
          <v-sheet
            min-height="50px"
            color="orange darken-3"
            style="padding-top: 10px"
            dark
          >
            <h2 style="font-weight: normal">Verify</h2>
          </v-sheet>
          <v-sheet min-height="20" />
          <v-row>
            <v-col cols="2" />
            <v-col cols="8">
              <v-text-field
                v-model="email"
                :value="email"
                label="Email Address"
                rounded
                outlined
              />
              <v-text-field
                v-model="code"
                :value="code"
                label="Code"
                rounded
                outlined
              />
            </v-col>
          </v-row>
          <v-btn @click="verify" color="blue" dark> Verify </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  data() {
    return {
      email: "",
      code: "",
      snackbar: false
    };
  },
  methods: {
    async verify() {
      try {
        await Auth.confirmSignUp(this.email.trim(), this.code);
        alert("Thank you for verifying! Please log in.")
        this.$router.push("/");
      } catch (error) {
        alert(error.message);
      }
    },
  },
};
</script>
